<template>
    <div id="footer-middle">
        <div class="footer-middle-wrapper container">

            <div class="main-compliance">
                <h4 v-t="'footer.compliance.title'"></h4>
                <p v-if="!hideCN" class="ip-detection" >{{ jurisdiction }}</p>
                <p v-t="'footer.compliance.description1'"></p>
                <p v-t="'footer.compliance.description4'"></p>
                <p v-t="'footer.compliance.description3'"></p>
                <p v-t="'footer.compliance.description5'"></p>
                <p v-t="'footer.compliance.description6'"></p>
                <p v-t="'footer.compliance.description7'"></p>
            </div>

            <div class="main-tab">
                <div class="tab-button-wrapper">
                    <div :class="`tab-button ${tabActive === key ? 'active' : ''}`" v-for="(tab, key) in tabData" @click="openTab(key)" v-t="`footer.tab.${tab.title}`"></div>
                </div>
                 <div class="tab-content-wrapper">
                     <div :class="`tab-content-item ${tabActive === key ? 'active' : ''}`" v-for="(tab, key) in tabData">
                        <h4 class="tab-title-mobile" v-t="`footer.tab.${tab.title}`" @click="openTab(key)"></h4>
                        <div class="content-wrapper">
                            <p v-if="tab.content" v-for="item in tab.content" v-t="`footer.tab.${item}`"></p>
                            <div v-if="tab.address" class="address-wrapper">
                                <div class="address-item" v-for="address in tab.address">
                                    <h4 v-t="`footer.tab.${address.title}`"></h4>
                                    <p v-for="content in address.content" v-t="`footer.tab.${content}`"></p>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>

            <div class="main-documents">
                <a class="documents" v-for="item in documents" v-t="item.title" :href="item.url ? item.url : '#'"></a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { decodeCountry } from '../../service/countryList';
    const tabData = [
        {
            title: "productsRiskDisclosureLabel",
            content: [
                "productsRiskDisclosureItem1",
                "productsRiskDisclosureItem2",
                "productsRiskDisclosureItem3",
            ]
        },
        {
            title: "brandAndEntityNoticeLabel",
            content: [
                "brandAndEntityNoticeItem1",
                "brandAndEntityNoticeItem2",
                "brandAndEntityNoticeItem3",
            ]
        },
        {
            title: "clientNoticeLabel",
            content: [
                "clientNoticeItem1",
                "clientNoticeItem2",
                "clientNoticeItem3",
            ]
        },
        {
            title: "dooEntitiesNoticeLabel",
            address: [
                {
                    title: "dooEntitiesAddress1Title",
                    content: [
                        "dooEntitiesAddress1Item1",
                        "dooEntitiesAddress1Item2",
                        "dooEntitiesAddress1Item3",
                    ]
                },
                {
                    title: "dooEntitiesAddress2Title",
                    content: [
                        "dooEntitiesAddress2Item1",
                        "dooEntitiesAddress2Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress3Title",
                    content: [
                        "dooEntitiesAddress3Item1",
                        "dooEntitiesAddress3Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress4Title",
                    content: [
                        "dooEntitiesAddress4Item1",
                        "dooEntitiesAddress4Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress5Title",
                    content: [
                        "dooEntitiesAddress5Item1",
                        "dooEntitiesAddress5Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress6Title",
                    content: [
                        "dooEntitiesAddress6Item1",
                        "dooEntitiesAddress6Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress7Title",
                    content: [
                        "dooEntitiesAddress7Item1",
                        "dooEntitiesAddress7Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress8Title",
                    content: [
                        "dooEntitiesAddress8Item1",
                        "dooEntitiesAddress8Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress9Title",
                    content: [
                        "dooEntitiesAddress9Item1",
                        "dooEntitiesAddress9Item2",
                    ]
                },
                {
                    title: "dooEntitiesAddress10Title",
                    content: [
                        "dooEntitiesAddress10Item1",
                        "dooEntitiesAddress10Item2",
                    ]
                },
            ]
        },
    ]
    
    const documents = [
        {
            title: "footer.documents.ClientAgreement",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Client_Agreement_(Doo_Prime)_(2023.09.14).pdf"
        },
        {
            title: "footer.documents.AMLCTFPolicy",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/AML_and_CTF_Policy_(Doo_Prime)_20230223.pdf"
        },
        {
            title: "footer.documents.ExecutionPolicy",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Execution_Policy_(Doo_Prime)_20230223.pdf"
        },
        {
            title: "footer.documents.RefundPolicy",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Refund_Policy_(Doo_Prime)_20230223.pdf"
        },
        {
            title: "footer.documents.RiskAcknowledgement",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Risk_Disclosure_And_Acknowledgement_(Doo_Prime)_2023_0223.pdf"
        },
        {
            title: "footer.documents.PrivacyPolicy",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Privacy_Policy_(Doo_Prime)_2023_0223.pdf"
        },
        {
            title: "footer.documents.WebsiteTAndC",
            url: "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/prime-website3/pdf/Website_Terms_and_Conditions_(Doo_Prime)_20230223.pdf"
        },
        {
            title: "footer.documents.Sitemap"
        }
    ]
    
    
    export default {
        name: "FooterMiddle",
        methods: {
            openTab(id) {
                if(this.tabActive === id) {
                    this.tabActive = -1;
                }
                else {
                    this.tabActive = id;
                }
            },
            async checkCountry() {
                const contryCode = this.getCookie('country');
                if(contryCode == "CN"){
                    this.hideCN = true;
                }
                try {
                    this.jurisdiction = await decodeCountry(contryCode);
                } catch (e) {
                    console.log(e)
                }
            },
            getCookie(name) {
                let nameEQ = name + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                }
                return null;
            },
        },
        data () {
            return {
                tabData: tabData,
                documents: documents,
                tabActive: 3,
                jurisdiction: '',
                hideCN: false,
            }
        },
        mounted() {
            this.checkCountry();
        },
    }
</script>

<style lang="scss">
    #footer-middle {
        padding: 40px 0 24px;
        background-color: #eff1f3;
        .main-compliance {
            h4 {
                font-size: 14px;
                font-weight: bold;
                line-height: 1.71;
                letter-spacing: 1.5px;
                color: #000018;
                margin-bottom: 14px;
            }
            p {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.71;
                letter-spacing: 1.5px;
                color: #000018;
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
            .ip-detection {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.71;
                letter-spacing: 1.5px;
                color: #16a0ff;
                margin-bottom: 16px;
            }
        }
        .main-tab {
            margin-top: 47px;
            .tab-button-wrapper {
                border-top: 1px solid #e4e4e4;
                border-bottom: 1px solid #e4e4e4;
                display: flex;
                @include mobile-screen() {
                    display: none;
                }
                .tab-button {
                    padding: 14px 0 17px;
                    font-size: 14px;
                    font-weight: bold;
                    letter-spacing: 1.5px;
                    color: #000018;
                    margin-right: 83px;
                    position: relative;
                    cursor: pointer;
                    &:hover {
                        color: #ec3f33;
                    }
                    &::after {
                        content: "";
                        background-color: #ec3f33;
                        position: absolute;
                        width: 0;
                        height: 2px;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        transition: 0.5s;
                    }
                    &.active {
                        color: #ec3f33;
                        &::after {
                            content: "";
                            width: 48px;
                            transition: 0.5s;
                        }
                    }
                }
            }
            .tab-content-wrapper {
                padding-top: 27px;
                .tab-content-item {
                    overflow: hidden;
                    @include mobile-screen() {
                        border-top: 1px solid #e4e4e4;
                        &:last-child {
                            border-bottom: 1px solid #e4e4e4;
                        }
                    }
                    .content-wrapper {
                        height: 0;
                    }
                    &.active {
                        .content-wrapper {
                            height: fit-content;
                            transition: 0.5s;
                        }
                        .tab-title-mobile {
                            &::after {
                                content: url('@/assets/icon-common-minus.svg');
                            }
                        }
                    }
                    p {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.71;
                        letter-spacing: 1.5px;
                        color: #000018;
                        margin-bottom: 12px;
                    }
                    .address-wrapper {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 40px 24px;
                        @include mobile-screen() {
                            grid-template-columns: 1fr;
                        }
                        h4 {
                            font-size: 14px;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: 1.75px;
                            margin-bottom: 24px;
                        }
                        p {
                            margin-bottom: 16px;
                        }
                        a {
                            color: #d91d22;
                            margin-bottom: 8px;
                            display: block;
                        }
                    }
                    .tab-title-mobile {
                        display: none;
                        position: relative;
                        @include mobile-screen() {
                            display: block;
                            padding: 16px 24px 16px 0;
                            &::after {
                                content: url('@/assets/icon-common-plus.svg');
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
        .main-documents {
            margin-top: 71px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            @include mobile-screen() {
                margin-top: 40px;
                justify-content: flex-start;
            }
            a {
                padding: 0 24px;
                display: block;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.71;
                letter-spacing: 1.5px;
                color: #36383b;
                text-decoration: none;
                position: relative;
                @include mobile-screen() {
                    padding: 0 16px;
                }
                &:not(:last-child)::after {
                    position: absolute;
                    content: "";
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 8px;
                    background-color:#36383b;
                }
            }
        }      
    }
</style>