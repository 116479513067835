import axios from 'axios';

const URL = "https://frontapi.finpoints.tech";

export const getLocation = async () => {
  try {
    const { data: { data } } = await axios.get(URL);
    return data;
  } catch (e) {
    return e;
  }
};
