<template>
    <section class="trading-platform">
        <div class="container">
            <div class="content-wrapper">
                <h2 class="title" v-t="'home.tradingPlatform.title'"></h2>
                <img class="img-intrade" src="@/assets/logo-intrade.svg" />
                <p class="desc" v-html="$t('home.tradingPlatform.desc')"></p>
                <div class="img-intrade-mobile mobile-only"></div>
                <div class="list-platform-wrapper">
                    <div :href="`https://www.dooprime.com/${lang}/trading-platform/metatrader5`" class="list-platform list-platform-text">
                        <img class="img-platform" src="@/assets/pages/home/platform-mt5.svg" />
                        <span>MetaTrader5</span>
                    </div>
                    <div :href="`https://www.dooprime.com/${lang}/trading-platform/metatrader4`" class="list-platform list-platform-text">
                        <img class="img-platform" src="@/assets/pages/home/platform-mt4.svg" />
                        <span>MetaTrader4</span>
                    </div>
                    <div :href="`https://www.dooprime.com/${lang}/trading-platform/tradingview`" class="list-platform list-platform-text">
                        <img class="img-platform" src="@/assets/pages/home/platform-tradingview.svg" />
                        <span>TradingView</span>
                    </div>
                    <div :href="`https://www.dooprime.com/${lang}/trading-platform/fix-api`" class="list-platform list-platform-img">
                        <img class="img-platform" src="@/assets/pages/home/platform-fixapi.svg" />
                    </div>                    
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        mounted() {
            this.lang = this.$i18n.locale
        },
        data () {
            return {
                lang: 'sc'
            }
        }
    }
</script>

<style lang="scss">
    #app.id {
        #page-home .trading-platform .content-wrapper .list-platform-wrapper {
            margin-top: 94px;
        }
    }
    #page-home {
        .trading-platform {
            margin-top: 162px;
            background-image: url(@/assets/pages/home/banner_trading_tools_PC_2560.png);
            background-size: cover;
            background-position: center;
            min-height: 820px;
            @include mobile-screen() {
                // background-image: url(@/assets/pages/home/banner_trading_tools_Mobile_1024.png);
                background-image: unset;
                margin-top: 0;
                text-align: center;
                min-height: 0;
            }
            .content-wrapper {
                padding-top: 101px;
                margin-left: 146px;
                @include mobile-screen() {
                    padding-top: 40px;
                    margin-left:0px;
                }
                .title {
                    font-size: 48px;
                    font-weight: normal;
                    line-height: normal;
                    letter-spacing: 0.75px;
                    color: #000018;
                    @include mobile-screen() {
                        font-size: 28px;
                        font-weight: 300;
                        line-height: normal;
                        letter-spacing: 1.5px;
                        margin-bottom: 14px;
                    }
                }
                .img-intrade {
                    width: 332px;
                    margin-top: 40px;
                    @include mobile-screen() {
                        margin-top: 0;
                        width: 168px;
                        padding-bottom:16px;
                    }
                }
                .img-intrade-mobile {
                    height: 375px;
                    background-image: url(@/assets/pages/home/banner_trading_tools_Mobile_1024.png);
                    background-position: center bottom;
                }
                .desc {
                    max-width: 437px;
                     font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #36383b;
                    margin-top: 25px;
                    @media screen and (max-width:1024px) and (min-width:835px) {
                        width: 992px !important;
                    }
                    @include tablet-screen() {
                        max-width: 335px;
                    }
                    @include mobile-screen() {
                        margin-top: 0;
                        color: #36383B;
                        font-size:14px;
                        width:335px;
                        // margin-bottom: 24px;
                        // margin-top: 16px;
                        margin:auto;
                    }
                }
                .btn {
                    margin-top: 72px;
                    min-width: 180px;
                }
                .list-platform-wrapper {
                    margin-top: 115px;
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 634px;

                    @include tablet-screen(){
                        margin-top: 80px;
                        flex-direction: column;
                    .list-platform:nth-last-child(1){
                        margin-bottom: 120px;
                    }

                    }

                    @include mobile-screen() {
                        // margin-top: 16px;
                        justify-content: center;
                        margin:auto;
                        padding-top:16px;
                        width:340px;
                    }
                    .list-platform {
                        width: 50%;
                        margin-bottom: 37px;
                        @include mobile-screen() {
                            max-width: 160px;
                            height: 48px;
                            border-radius: 4px;
                            box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);
                            margin: 0 0 16px;
                            &:nth-child(even) {
                                margin-left: 8px;
                            }
                            &:nth-child(odd) {
                                margin-right: 8px;
                            }
                        }
                        &.list-platform-text {
                            display: flex;
                            align-items: center;
                            width:267px;
                            @include mobile-screen() {
                                justify-content: center;
                            }
                            .img-platform {
                                width: 64px;
                                margin-right: 8px;
                                @include mobile-screen() {
                                    width: 32px;
                                }
                            }
                            span {
                                font-size: 24px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: 1.75;
                                letter-spacing: normal;
                                color: #000018;
                                @include mobile-screen() {
                                    font-size: 14px;
                                    font-weight: normal;
                                }
                            }
                        }
                        &.list-platform-img {
                            @include mobile-screen() {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            .img-platform {
                                max-width: 205px;
                                @include mobile-screen() {
                                    max-width: 120px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
</style>