<template>
    <footer id="footer">
        <FooterBanner />
        <!-- <FooterTop />
        <FooterMiddle />
        <FooterBottom /> -->
        <FooterCms />
    </footer>
</template>

<script>
import FooterBanner from '@/components/Footer/FooterBanner'
import FooterTop from '@/components/Footer/FooterTop'
import FooterMiddle from '@/components/Footer/FooterMiddle'
import FooterBottom from '@/components/Footer/FooterBottom'
import FooterCms from "@/components/Footer/footer-cms.vue";
export default {
    name: "Footer",
    components: {
        FooterBanner,
        FooterTop,
        FooterMiddle,
        FooterBottom,
        FooterCms,
    }
}
</script>

<style lang="scss"></style>