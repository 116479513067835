import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView'
import Page404View from '@/views/Page404View'
import i18n from '@/i18n'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)

import Root from './Root'

const routes = [
    {
        path: "/",
        redirect: () => {
            let ca = document.cookie.split(';')
            let nameEQ = 'prefLang' + "=";
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
        }
    },
    {
        path: "*",
        component: Page404View
    },
    {
        path: '/:locale',
        component: Root,
        children: [
            {
                path: '',
                name: 'home',
                component: HomeView
            },
        ],
    },
    {
        path: '/:locale/404',
        component: Page404View,
    },
    {
        path: '/:locale/*',
        component: Page404View,
    },
]

const router = new VueRouter({
    mode: "history",
    routes
})

router.beforeEach((to, _, next) => {
    if (to.path.includes("localhost")) {
      return next();
    } else {
      i18n.locale = to.path.split("/")[1];
  
      document.documentElement.setAttribute("lang", i18n.locale);
      return next();
    }
  });

export default router
