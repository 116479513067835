import { render, staticRenderFns } from "./index.vue?vue&type=template&id=65bed6ce"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=65bed6ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports