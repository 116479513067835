import axios from "axios";

const url = "https://cms-v2-manage.finpoints.tech/api/v1/public/getFooter";
const apiKey = "6m7boAkl8q0U6hgJ";
// const url = "https://dev-cms-v2.finpoints.tech/api/v1/public/getFooter";
// const apiKey = "abc1234!@#$";

export const footer = async (data) => {
  try {
    const header = {
      apiKey,
    };

    const payload = await axios({
      method: "get",
      url: url,
      headers: header,
      params: data,
    });

    return payload;
  } catch (e) {
    return e;
  }
};
