<template>
    <div id="page-404" class="container">
        <div class="desc">
            <p class="text-1" v-html="$t('pageMissing.pageNotFound')"></p>
            <p class="text-2" style="text-align: center;">
                <span v-html="$t('pageMissing.automaticReturn')"> </span>
            </p>
            <div class="button-home">


                <button @click="redirectPage()">
                    <p v-t="'pageMissing.buttonText'"></p>
                </button>
            </div>


        </div>
    </div>
</template>


<script>


export default {
    methods: {
        redirectPage() {
            this.$router.push('/home')
        },
        getCookie(name) {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
    },
    async created() {
        let lang = this.getCookie('prefLang')
        await setTimeout(3000);
        let seconds = document.getElementById("seconds");
        let timer_second = 5;

        countdown(timer_second);
        function countdown(balance) {
            var that = this
            setTimeout(function () {

                balance -= 1;
                seconds.innerHTML = balance;
                if (balance > 0) {
                    countdown(balance);
                } else {
                    console.log(lang)
                    document.location.href = `/${lang}`
                }
            }, 1000);
        }
    }

}


</script>




<style lang="scss">
#page-404 {
    max-width: none;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-image: url('http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-ib/PWEB-56/404.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .desc {

        .text-1 {
            position: relative;
            display: flex;
            justify-content: center;
            padding-top: 72vh;
            font-family: MicrosoftYaHei;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            text-align: center;
            color: var(--1-000018);

            @include desktop-screen() {
                .break {
                    display: none;
                    padding-bottom: 8px;
                }
            }

        }

        .text-2 {
            position: relative;
            display: flex;
            justify-content: center;
            padding-top: 24px;
        }

        .button-home {
            position: relative;
            display: flex;
            justify-content: center;
            padding-top: 40px;
            padding-bottom: 40px;


            button {
                padding: 12px 40px;
                border-radius: 4px;
                border: 2px solid #d91d22;
                background-color: #d91d22;

                p {
                    font-family: MicrosoftYaHei;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.5;
                    letter-spacing: 1.5px;
                    text-align: center;
                    color: #ffffff;
                }
            }
        }


    }
}
</style>