<template>
    <div id="footer-bottom">
        <div class="footer-bottom-wrapper container">
            <p class="content copyright-content"></p>
        </div>
    </div>
</template>

<script>
    import i18n from '../../i18n'
    export default {
        name: "FooterBottom",
        methods: {
            copyrightInit(){
                let urlLang = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1') || "sc";
                let copyrightContent = "";
                let lang = urlLang;
                let entityName = "Doo Prime Vanuatu Limited";
                let emtityRegNo = "700238";
                let currentYear = new Date().getFullYear();
                let copyrightTag = document.querySelectorAll(".copyright-content");

                switch ( lang ) {
                    case 'sc':
                        copyrightContent = '© ' + currentYear + ' Doo Prime。版权所有。';
                        break;
                    case 'tc':
                        copyrightContent = '© ' + currentYear + ' Doo Prime。版權所有。';
                        break;
                    /*case 'es':
                        copyrightContent = 'Este sitio web es propiedad y está operado por ' + entityName + ' (número de compañía ' + emtityRegNo + '). © ' + currentYear + ' Doo Prime. reservados todos los derechos.';
                        break;
                    case 'pt':
                        copyrightContent = 'Este site pertence e é operado pela ' + entityName + ' (número da empresa ' + emtityRegNo + '). © ' + currentYear + ' Doo Prime. todos os direitos reservados.';
                        break;
                    case 'kr':
                        copyrightContent = '이 웹사이트는' + entityName + ' (회사 번호 ' + emtityRegNo + ') 가 소유하고 운영합니다. © ' + currentYear + ' 두프라임. 판권 소유.';
                        break;
                    case 'jp':
                        copyrightContent = 'このウェブサイトは、' + entityName + ' (会社番号 ' + emtityRegNo + ') によって所有および運営されています。 © ' + currentYear + ' ドゥープライム.';
                        break;
                    case 'vn':
                        copyrightContent = 'Trang web này được sở hữu và điều hành bởi ' + entityName + ' (số công ty ' + emtityRegNo + '). © ' + currentYear + ' Doo Prime. Đã đăng ký Bản quyền.';
                        break;
                    case 'th':
                        copyrightContent = 'เว็บไซต์นี้เป็นเจ้าของและดำเนินการโดย ' + entityName + ' (หมายเลขบริษัท ' + emtityRegNo + '). © ' + currentYear + ' ดูไพรม์. สงวนลิขสิทธิ์.';
                        break;*/
                    default:
                        copyrightContent = ' © ' + currentYear + ' Doo Prime. All Rights Reserved.';
                        break;
                }
                for(let i=0; i<copyrightTag.length; i++) {
                    copyrightTag[i].innerHTML = copyrightContent;
                }
            }
        },
        mounted() {
            this.copyrightInit()
        },
    }
</script>

<style lang="scss">
    #footer-bottom {
        font-size: 14px;
        color: #ffffff;
        padding: 18px 0;
        background-color: #000018;
        text-align: center;
    }
</style>