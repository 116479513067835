<template>
    <section id="contact" class="contact">
        <div class="contact-container">
            <div class="contact-card">
                <div class="section-left desktop-only">
                    <div v-if="isDisableEuContent" class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.global'"></h4>
                        <p class="item-desc" v-t="'home.contact.globalNo'"></p>
                    </div>
                    <div class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.china'"></h4>
                        <p class="item-desc" v-t="'home.contact.chinaNo'"></p>
                    </div>
                    <div class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.vietnam'"></h4>
                        <p class="item-desc" v-t="'home.contact.vietnamNo'"></p>
                    </div>
                    <div class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.thailand'"></h4>
                        <p class="item-desc" v-t="'home.contact.thailandNo'"></p>
                    </div>
                    <div class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.korea'"></h4>
                        <p class="item-desc" v-t="'home.contact.koreaNo'"></p>
                    </div>
                    <div class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.inquiry'"></h4>
                        <p class="item-desc" v-t="'home.contact.supportEmail'"></p>
                    </div>
                    <div class="contact-item">
                        <h4 class="item-title" v-t="'home.contact.marketing'"></h4>
                        <p class="item-desc" v-t="'home.contact.marketingEmail'"></p>
                    </div>
                </div>
                <div class="section-right">
                    <h4 class="title" v-t="'home.contact.formTitle'"></h4>
                    <p class="desc" v-t="'home.contact.formDesc'"></p>
                    <div class="form-wrapper">
                        <ContactForm />
                    </div>
                </div>
            </div>
            <div class="contact-disclosure desktop-only">
                <h4 class="title" v-t="'home.contact.disclosureTitle'"></h4>
                <p class="desc" v-t="'home.contact.disclosureDesc'"></p>
            </div>
        </div>
    </section>
</template>

<script>
import ContactForm from './ContactForm'
import { disableEuContent } from "@/service/disableEuContact";
export default {
    components: {
        ContactForm
    },
    data() {
        return {
            isDisableEuContent: disableEuContent(),
        }
    },
}
</script>

<style lang="scss">
#page-home {
    .contact {
        padding-top: 160px;
        padding-bottom: 160px;

        @include mobile-screen() {
            padding-top: 40px;
            padding-bottom: 93px;
        }

        .contact-container {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            max-width: 1392px;

            .contact-card {
                border-radius: 2px;
                box-shadow: 0 0 25px 0 #e7e7e7;
                display: flex;

                @include mobile-screen() {
                    border-radius: 0;
                    box-shadow: unset;
                }

                .section-left {
                    padding: 82px 56px 80px;
                    background-color: #f8f9fb;
                    min-width: 513px;

                    .contact-item {
                        .item-title {
                            font-size: 16px;
                            font-weight: 300;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            color: #000018;
                        }

                        .item-desc {
                            font-size: 16px;
                            font-weight: normal;
                            line-height: normal;
                            letter-spacing: 0.5px;
                            color: #00001c;
                            margin-top: 11px;
                        }

                        &:not(:last-child) {
                            margin-bottom: 42px;
                        }
                    }
                }

                .section-right {
                    padding: 62px 61px;
                    width: 100%;

                    @include mobile-screen() {
                        padding: 0 10px;
                        text-align: center;
                    }

                    .title {
                        font-size: 48px;
                        font-weight: 300;
                        line-height: normal;
                        letter-spacing: 1.5px;
                        color: #000018;
                        margin-bottom: 31px;

                        @include mobile-screen() {
                            font-size: 28px;
                            margin-bottom: 14px;
                        }
                    }

                    .desc {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: 0.88px;
                        text-align: left;
                        color: #000018;

                        @include mobile-screen() {
                            text-align: center;
                        }
                    }
                }
            }

            .contact-disclosure {
                margin-top: 26px;

                .title {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: normal;
                    letter-spacing: 1.56px;
                    color: #757575;
                    margin-bottom: 7px;
                }

                .desc {
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 2.08;
                    letter-spacing: 1.33px;
                    color: #757575;
                }
            }
        }

    }
}
</style>