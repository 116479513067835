var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-form-container"},[_c('FormulateForm',{attrs:{"name":"contact"},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"form-row"},[_c('FormulateInput',{attrs:{"type":"text","name":"firstName","placeholder":_vm.$t('home.contact.fieldFirstName'),"validation":"required","validation-messages":{
                    required: _vm.$t('home.contact.fieldRequired'),
                }}}),_c('FormulateInput',{attrs:{"type":"text","name":"lastName","validation-name":_vm.$t('home.contact.fieldLastName'),"placeholder":_vm.$t('home.contact.fieldLastName'),"validation":"required","validation-messages":{
                    required: _vm.$t('home.contact.fieldRequired'),
                }}})],1),_c('div',{staticClass:"form-row"},[_c('PhoneInput'),_c('FormulateInput',{attrs:{"type":"email","name":"email","validation-name":_vm.$t('home.contact.fieldEmail'),"placeholder":_vm.$t('home.contact.fieldEmail'),"validation":"required","validation-messages":{
                    required: _vm.$t('home.contact.fieldRequired'),
                }}})],1),_c('div',{staticClass:"form-row"},[_c('FormulateInput',{attrs:{"type":"textarea","validation-name":_vm.$t('home.contact.fieldMessage'),"placeholder":_vm.$t('home.contact.fieldMessage'),"name":"message","validation":"required","validation-messages":{
                    required: _vm.$t('home.contact.fieldRequired'),
                }}})],1),_c('FormulateInput',{staticClass:"btn-submit",attrs:{"type":"submit","name":_vm.$t('home.contact.fieldSubmit')}}),(_vm.formMessage)?_c('p',{class:("form-message " + (_vm.isFormSuccess === true ? '' : 'error'))},[_vm._v(_vm._s(_vm.formMessage))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }