<template>
    <div id="footer-top">
        <div class="footer-top-wrapper container">
            <div class="section-about">
                <img class="about-logo" src="@/assets/logo-dp-simple.svg" alt="logo DooPrime" />
                <div class="about-social-container">
                    <div><a href="https://www.facebook.com/DooPrime/"><img src="@/assets/facebook-logo.svg"
                                alt="logo DooPrime" /></a></div>
                    <div><a href="https://www.instagram.com/dooprime/ "><img src="@/assets/instagram-logo.svg"
                                alt="logo DooPrime" /></a></div>
                    <div><a
                            href=" https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited "><img
                                src="@/assets/linkedin-logo.svg" alt="logo DooPrime" /></a></div>
                    <div><a href="https://twitter.com/DooPrime"><img src="@/assets/twitter-logo.svg"
                                alt="logo DooPrime" /></a></div>
                    <div><a href="https://mp.weixin.qq.com/s/XgK46goPzqy3cRHczrd1Ag"><img src="@/assets/wechat-logo.svg"
                                alt="logo DooPrime" /></a></div>
                </div>
            </div>
            <div class="contact-container phone-1">
                <h4 class="contact-title upper" v-t="'footer.contact.phoneTitle'"></h4>
                <p v-if="isDisableEuContent" class="contact-item" v-t="'footer.contact.phoneEurope'"></p>
                <p class="contact-item" v-t="'footer.contact.phoneAsia'"></p>
                <p v-if="!isDisableEuContent" class="contact-item" v-t="'footer.contact.phoneSingapore'"></p>
                <p v-if="isDisableEuContent" class="contact-item" v-t="'footer.contact.phoneSingapore'"></p>
                <p class="contact-item" v-t="'footer.contact.phoneChina'"></p>
            </div>
            <div class="contact-container email">
                <h4 class="contact-title" v-t="'footer.contact.emailTitle'"></h4>
                <p class="contact-item" v-t="'footer.contact.emailAccountManager'"></p>
                <p class="contact-item" v-t="'footer.contact.emailTechnicalSupport'"></p>
            </div>
            <div class="mobile-social-container">
                <div><a href="https://www.facebook.com/DooPrime/"><img src="@/assets/facebook-logo.svg"
                            alt="logo DooPrime" /></a></div>
                <div><a href="https://www.instagram.com/dooprime/ "><img src="@/assets/instagram-logo.svg"
                            alt="logo DooPrime" /></a></div>
                <div><a
                        href=" https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGv8ApVYNNOdwAAAXzj1XZwLGl13ZbnzGUihzUOn48chZef_12RQo0ClAQOSm8OMVcKrjF6Wocn811XJ-2Ue--xfddrLZuMOPbzwSyfbA3hWMaSC_pRHI_r2rXwLnxk9fApfv0=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fdoo-prime-limited "><img
                            src="@/assets/linkedin-logo.svg" alt="logo DooPrime" /></a></div>
                <div><a href="https://twitter.com/DooPrime"><img src="@/assets/twitter-logo.svg" alt="logo DooPrime" /></a>
                </div>
                <div><a href="https://mp.weixin.qq.com/s/XgK46goPzqy3cRHczrd1Ag"><img src="@/assets/wechat-logo.svg"
                            alt="logo DooPrime" /></a></div>
            </div>
        </div>
    </div>
</template>

<script>
/*const footerMenu = [
    {
        title: "marketTitle",
        items: [
            ["marketSecurities", "marketSecuritiesUrl"],
            ["marketFutures", "marketFuturesUrl"],
            ["marketForex", "marketForexUrl"],
            ["marketMetals", "marketMetalsUrl"],
            ["marketCommodities", "marketCommoditiesUrl"],
            ["marketStock", "marketStockUrl"]
        ],
    },
    {
        title: "toolsTitle",
        group: [
            {
                title: "toolsPlatformTitle",
                items: [
                    ["toolsPlatformMetaTrader4", "toolsPlatformMetaTrader4Url"],
                    ["toolsPlatformMetaTrader5", "toolsPlatformMetaTrader5Url"],
                    ["toolsPlatformMT4vsMT5", "toolsPlatformMT4vsMT5Url"],
                    ["toolsPlatformDooPrimeInTrade", "toolsPlatformDooPrimeInTradeUrl"],
                    ["toolsPlatformTradingView", "toolsPlatformTradingViewUrl"],
                    ["toolsPlatformFIXAPI", "toolsPlatformFIXAPIUrl"],
                ]
            },
            {
                title: "toolsSocialTitle",
                items: [
                    ["toolsSocialOutrade", "toolsSocialOutradeUrl"],
                    ["toolsSocialMyfxbook", "toolsSocialMyfxbookUrl"],
                    ["toolsSocialFOLLOWME", "toolsSocialFOLLOWMEUrl"],
                ]
            },
            {
                title: "toolsAnalysisTitle",
                items: [
                    ["toolsAnalysisTradingCentral", "toolsAnalysisTradingCentralUrl"],
                    ["toolsAnalysisEconomicCalendar", "toolsAnalysisEconomicCalendarUrl"],
                ]
            },
            {
                title: "toolsTradingToolsTitle",
                items: [
                    ["toolsTradingToolsVPS", "toolsTradingToolsVPSUrl"],
                ]
            },
        ]
    },
    {
        title: "tradingTitle",
        group: [
            {
                title: "tradingTransactionOverviewTitle",
                items: [
                    ["tradingTransactionOverviewPricingModel", "tradingTransactionOverviewPricingModelUrl"],
                    ["tradingTransactionOverviewTechnology", "tradingTransactionOverviewTechnologyUrl"],
                    ["tradingTransactionOverviewAccountsComparison", "tradingTransactionOverviewAccountsComparisonUrl"],
                    ["tradingTransactionOverviewFlexibleFunding", "tradingTransactionOverviewFlexibleFundingUrl"],
                ]
            },
            {
                title: "tradingConditionsTitle",
                items: [
                    ["tradingConditionContractSpecifications", "tradingConditionContractSpecificationsUrl"],
                    ["tradingConditionTradingCalendar", "tradingConditionTradingCalendarUrl"],
                    ["tradingConditionLeverage", "tradingConditionLeverageUrl"],
                    ["tradingConditionOvernightInterest", "tradingConditionOvernightInterestUrl"],
                    ["tradingConditionDividend", "tradingConditionDividendUrl"],
                ]
            },
        ]
    },
    {
        title: "partnershipTitle",
        group: [
            {
                title: "partnershipPromotionTitle",
                items: [
                    ["partnershipPromotionPointsMall", "partnershipPromotionPointsMallUrl"],
                    ["partnershipPromotionDooTraderTradingCompetition", "partnershipPromotionDooTraderTradingCompetitionUrl"],
                ]
            },
            {
                title: "partnershipPartnersTitle",
                items: [
                    ["partnershipPartnersIntroducingBrokers", "partnershipPartnersIntroducingBrokersUrl"]
                ]
            },
        ]
    },
    {
        title: "aboutTitle",
        items: [
            ["aboutOurStory", "aboutOurStoryUrl"],
            ["aboutCorporateSocialResponsibility", "aboutCorporateSocialResponsibilityUrl"],
            ["aboutMediaCenter", "aboutMediaCenterUrl"],
            ["aboutCompliance", "aboutComplianceUrl"],
            ["aboutContactUs", "aboutContactUsUrl"],
            ["aboutCareers", "aboutCareersUrl"],
            ["aboutHelpCentre", "aboutHelpCentreUrl"],
        ],
    },
] */
import { disableEuContent } from "@/service/disableEuContact";
export default {

    name: "FooterTop",
    methods: {
        setMenuActive(id) {
            if (this.menuTabActive === id) {
                this.menuTabActive = -1;
            } else {
                this.menuTabActive = id;
            }
        },
    },
    data() {
        return {
            //footerMenu: footerMenu,
            menuTabActive: -1,
            isDisableEuContent: disableEuContent(),
        }
    },
}
</script>

<style lang="scss">
#footer-top {
    position: relative;
    padding: 81px 0 42px;
    background-color: #f7f8f9;

    @include mobile-screen() {
        padding: 32px 0 25px;
    }

    .mobile-social-container {
        display: none;

        @include mobile-screen() {
            display: flex;
            gap: 20px;
            margin-top: 48px;
        }
    }

    .email {
        @media screen and (max-width: 1213px) and (min-width:1025px) {
            max-width: initial;
            width: 380px;
        }
    }

    .phone-1 {
        @media screen and (max-width: 1213px) and (min-width:1025px) {
            width: 230px ;
        }
        @include mobile-screen() {
            width:100% !important;
        }
        p {
            width: 50% !important;
            display: inline-block !important;
            vertical-align: top;
            @include mobile-screen() {
                display: block !important;
                vertical-align: initial;
            }
        }
    }

    .footer-top-wrapper {
        display: flex;
        justify-content: space-around;
        gap:49px;
        max-width: 1500px;

        @include mobile-screen() {
            flex-direction: column;
            max-width: initial;
        }

        .secret {
            opacity: 0;

            @include mobile-screen() {
                display: none;
            }
        }

        .section-about {
            width: 20%;
            min-width: 240px;
            position:relative;
            &:before {
                content:'';
                width:1px;
                height:100%;
                border-right: 1px solid #c7c7c7;
                position:absolute;
                right:0;
                top:0;
                @include mobile-screen() {
                    display:none;
                }
            }

            @include mobile-screen() {
                border-right: none;

            }

            .about-logo {
                margin-bottom: 40px;
                max-width: 214px;

                @include mobile-screen() {
                    margin-bottom: 39px;
                    width: 171px;
                }
            }

            .about-social-container {
                display: flex;
                justify-content: space-around;
                max-width: 214px;

                @include mobile-screen() {
                    display: none;
                }
            }

            .contact-container {
                margin-bottom: 56px;

                @include mobile-screen() {
                    margin-bottom: 47px;
                }

                .contact-title {
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: 1.71px;
                    color: #000018;
                    margin-bottom: 16px;
                }

                .contact-item {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.57;
                    letter-spacing: 1.5px;
                    color: #36383b;
                    margin-bottom: 16px;
                }

                
            }
            
        }

        .upper {
            @include mobile-screen() {
                margin-top: 0;

            }
        }

        h4 {
            font-family: MicrosoftYaHei;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: 1.71px;
            padding-bottom: 16px;
            color: #000018;

            @include mobile-screen() {
                margin-top: 48px;
            }
        }

        p {
            padding-bottom: 16px;
            font-family: MicrosoftYaHei;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.57;
            color: #36383B;
            letter-spacing: 1.5px;
            padding-bottom: 16px;
            display: inline-block !important;
            vertical-align: top;

            @include mobile-screen() {
                display: block !important;
                vertical-align: initial;
            }
        }

        .section-menu {
            display: flex;
            display: none;
            width: 100%;
            justify-content: space-between;

            @include mobile-screen() {
                flex-wrap: wrap;
            }

            .section-menu-row {
                overflow: hidden;

                @include mobile-screen() {
                    width: 100%;
                    border-top: 1px solid #e4e4e4;

                    &:last-child {
                        border-bottom: 1px solid #e4e4e4;
                    }
                }

                .row-title {
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1.5px;
                    color: #36383b;
                    margin-bottom: 16px;
                    position: relative;

                    @include mobile-screen() {
                        padding: 16px 30px 16px 0;

                        &::after {
                            content: url('@/assets/icon-common-plus.svg');
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                .row-submenu-1 {
                    &:nth-child(n+3) {
                        margin-top: 32px;
                    }

                    .submenu-1-title {
                        position: relative;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        letter-spacing: 1.5px;
                        color: #000000;
                        margin-bottom: 16px;
                        padding-left: 7px;

                        &::before {
                            content: "";
                            position: absolute;
                            height: 100%;
                            width: 2px;
                            top: 0;
                            left: 0;
                            background-color: #d91d22;
                        }
                    }
                }

                @include mobile-screen() {
                    .row-submenu-wrapper {
                        max-height: 0;
                        margin-top: 0;
                    }

                    .row-title {
                        margin-bottom: 0;
                    }

                    &.active {
                        .row-submenu-wrapper {
                            max-height: 100vh;
                            margin-top: 16px;
                        }

                        .row-title {
                            &::after {
                                content: url('@/assets/icon-common-minus.svg');
                            }
                        }
                    }
                }
            }
        }

        a {
            text-decoration: none;
            display: block;
            font-size: 14px;
            font-weight: normal;
            line-height: normal;
            letter-spacing: 1.5px;
            color: #000018;
            margin-bottom: 16px;

            &:hover {
                color: #ec3f33;
            }
        }
    }

}
</style>