<template>
    <div class="contact-form-container">
        <FormulateForm
            @submit="handleSubmit"
            name="contact"
        >
            <div class="form-row">
                <FormulateInput 
                    type="text"
                    name="firstName"
                    :placeholder="$t('home.contact.fieldFirstName')"
                    validation="required"
                    :validation-messages="{
                        required: $t('home.contact.fieldRequired'),
                    }"
                />
                <FormulateInput 
                    type="text"
                    name="lastName"
                    :validation-name="$t('home.contact.fieldLastName')"
                    :placeholder="$t('home.contact.fieldLastName')"
                    validation="required"
                    :validation-messages="{
                        required: $t('home.contact.fieldRequired'),
                    }"
                />
            </div>
            <div class="form-row">
                <PhoneInput />
                <FormulateInput 
                    type="email"
                    name="email"
                    :validation-name="$t('home.contact.fieldEmail')"
                    :placeholder="$t('home.contact.fieldEmail')"
                    validation="required"
                    :validation-messages="{
                        required: $t('home.contact.fieldRequired'),
                    }"
                />
            </div>
            <div class="form-row">
                <FormulateInput
                    type="textarea"
                    :validation-name="$t('home.contact.fieldMessage')"
                    :placeholder="$t('home.contact.fieldMessage')"
                    name="message"
                    validation="required"
                    :validation-messages="{
                        required: $t('home.contact.fieldRequired'),
                    }"
                />
            </div>
            <FormulateInput
                type="submit"
                :name="$t('home.contact.fieldSubmit')"
                class="btn-submit"
            />
            <p v-if="formMessage" :class="`form-message ${isFormSuccess === true ? '' : 'error'}`">{{ formMessage }}</p>
        </FormulateForm>
    </div>
</template>

<script>
    import PhoneInput from './PhoneInput'

    export default {
        components: {
            PhoneInput
        },
        methods: {
            handleSubmit(formValues) {
                this.axios.post('/api/v1/mail/send', {
                    formValues
                })
                .then(response => {
                    console.log(response)
                    if(response.data.status === 200) {
                        this.formMessage = this.$i18n.t('home.contact.submitted')
                        // this.$formulate.reset('contact')
                        this.isFormSuccess = true;
                        this.$formulate.reset('contact');
                    } else {
                        this.formMessage = this.$i18n.t('home.contact.fieldsArrorWhenSubmit')
                        this.isFormSuccess = false
                    }
                })
                .catch((error) => {
                    //this.formSubmit = ref(false);
                    this.formMessage = this.$i18n.t('home.contact.fieldsArrorWhenSubmit')
                    this.isFormSuccess = false
                    this.errors = error.response.data.errors
                });
            },
            resetForm () {
                this.$formulate.reset('contact')
            },
        },
        data () {
            return {
                formMessage: '',
                isFormSuccess: false
            }
        }
    }
</script>

<style lang="scss">
    #page-home {
        .contact-form-container {
            margin-top: 82px;
            @include mobile-screen() {
                margin-top: 24px;
            }
            .form-row {
                display: flex;
                margin: 0 -12px 32px;
                @include mobile-screen() {
                    flex-direction: column;
                    &:not(:last-child) {
                        margin: 0 0 24px;
                    }
                }
                > div {
                    margin: 0 12px;
                    @include mobile-screen() {
                        margin: 0 0 24px;
                    }
                    &:last-child {
                        @include mobile-screen() {
                            margin: 0;
                        }
                    }
                }
                .formulate-input {
                    flex: 1;
                    input {
                        width: 100%;
                        border-radius: 1px;
                        font-size: 16px;
                        border: 1px solid #e4e4e4;
                        padding: 20px 16px 19px;
                        background-color: #ffffff;
                    }
                    textarea {
                        width: 100%;
                        font-size: 16px;
                        border-radius: 1px;
                        border: 1px solid #e4e4e4;
                        padding: 20px 16px 19px;
                        background-color: #ffffff;
                        min-height: 64px;
                        height: 64px;
                    }
                }
            }
            .btn-submit {
                margin-top: 38px;
                @include mobile-screen() {
                    margin-top: 0;
                }
                button {
                    padding: 22px 22px 21px;
                    border-radius: 2px;
                    background-color: #d91d22;
                    border: none;
                    width: 100%;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: normal;
                    letter-spacing: 1.78px;
                    color: #ffffff;
                    &:hover, &:active {
                        background-color: #ff4e53;
                        cursor: pointer;
                    }
                }
            }
            .formulate-input-error {
                list-style: none;
                color: #d91d22;
            }
            .form-message {
                font-size: 20px;
                color: #55c159;
                margin-top:30px;
                text-align: center;
                &.error {
                    color: #d91d22;
                }
            }
        }
    }
</style>