<template>
    <div class="form-group">
        <FormulateInput 
            type="select"
            class="input-select"
            name="countryID"
            validation="required"
            :options="getPhoneCode()"
            v-on:change="e => changeCountryAndPhoneCodeDisplay(e.target.value)"
            v-model="currentCountryIndex"
        />
        <FormulateInput
            type="hidden"
            class="input-hidden"
            name="phoneCode"
            v-model="currentPhoneCode"
        />
        <FormulateInput
            type="hidden"
            class="input-hidden"
            name="country"
            v-model="currentCountry"
        />
        <FormulateInput 
            type="tel"
            name="phone"
            :validation-name="$t('home.contact.fieldPhone')"
            minlength="6"
            maxlength="20"
            validation="required|matches:/^[0-9]+$/"
            :validation-messages="{
                required: $t('home.contact.fieldRequired'),
                matches: $t('home.contact.phoneMustBeNum')
            }"
        />
        <span class="phone-flag">
            <img :src="`${baseImg}/flags/${currentCountryCode}.png`" />
            {{ currentPhoneCode }}
        </span>
    </div>
</template>

<script>
    import phoneListData from '@/assets/js/phonecode-master.json'
    
    let baseImg = 'http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo-prime-ib/img'
    let initialCountryCode = 'cn'

    export default {
        methods: {
            getPhoneCode() {
                let phoneList = {}
                let lang = this.lang = this.$i18n.locale;
                let countryNameLang;

                switch(lang){
                    case "sc":
                    case "tc":
                        countryNameLang = "nameZh"
                        break;
                    default:
                        countryNameLang = "nameEn"
                }
                
                console.log("lang", lang)
                for(let i=0; i<phoneListData.length; i++) {
                    phoneList[i] = `(${phoneListData[i].phonecode}) ${phoneListData[i][countryNameLang]}`
                    if(phoneListData[i].alpha2 === this.currentCountryCode) {
                        this.changeCountryAndPhoneCodeDisplay(i);
                        this.currentCountryIndex = i;
                    }
                }
                return phoneList
            },
            changeCountryAndPhoneCodeDisplay(i) {
                this.currentCountryCode = phoneListData[i].alpha2
                this.currentCountry = phoneListData[i].nameZh
                this.currentPhoneCode = phoneListData[i].phonecode
                // console.log('asas', this.currentCountryCode, this.currentPhoneCode)
            }
        },
        // mounted () {
        //     console.log(phoneListData)
        // },
        data () {
            return {
                currentCountryCode: initialCountryCode, 
                currentCountry: '', 
                currentCountryIndex: 0,
                currentPhoneCode: '',
                baseImg: baseImg
            }
        }
    }
</script>

<style lang="scss">
    #page-home {
        .contact {
            .form-group {
                flex: 1;
                display: flex;
                position: relative;
                > div:first-child {
                    max-width: 100px;
                    position: absolute;
                    top: 0;
                    left: 2px;
                }
                select {
                    max-width: 100px;
                    margin: 5px;
                    border-radius: 1px;
                    border: none;
                    padding: 19px 16px 18px;
                    background-color: transparent;
                    color: transparent;
                    &:focus-visible,
                    &:focus {
                        outline: none;
                    }
                    option {
                        color: #000018;
                    }
                }
                input {
                    border: 1px solid #e4e4e4;
                    padding-left: 110px !important;
                }
                .input-hidden {
                    display: none;
                }
                .phone-flag {
                    position: absolute;
                    top: 21px;
                    left: 9px;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: normal;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    color: #000018;
                    pointer-events: none;
                    img {
                        width: 20px;
                        height: 14px;
                        margin-right: 24px;
                        position: relative;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 45%;
                        left: 26px;
                        width: 0;
                        height: 0;
                        border-top: 4px solid #999999;
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                    }
                }
            }
        }
    }
</style>