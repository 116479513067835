const disableCountryList = [
  "AX",
  "AD",
  "AT",
  "BE",
  "BG",
  "IC",
  "HR",
  "CZ",
  "CY",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GI",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "MC",
  "NL",
  "NO",
  "PL",
  "PT",
  "RO",
  "SM",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB",
];

export const getCookie = (name) => {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const disableEuContent = () => {
  let isDisableEuContent = true;

  const currentCountry = getCookie("country");
  if (disableCountryList.includes(currentCountry)) {
    isDisableEuContent = false;
  }
  console.log(currentCountry);
  return isDisableEuContent;
};
