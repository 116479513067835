<template>
    <div id="app" :class="$i18n.locale">
        <router-view />
    </div>
</template>

<script>
import { getLocation } from "./api/location";
import i18n from './i18n'
const supportedLocales = ["tc", "sc", "th", "vn", "kr", "jp", "en", "es", "pt"]
const countryCodeLang = {
    TW: 'tc',
    HK: 'tc',
    CN: 'sc',
    TH: 'th',
    VN: 'vn',
    KR: 'kr',
    JP: 'jp',
    IN: 'en',
    ES: 'es',
    KR: 'pt',
    //ID: 'id',
}

const partnerCountryCode = ["MO", "JP", "MN", "TW", "RU", "AF", "BD", "BT", "IO", "IN", "MV", "NP", "PK", "BN", "KH", "ID", "LA", "MY", "MM", "PH", "SG", "TH", "TL", "VN", "AU", "NZ", "FJ", "NC", "PG", "SB", "VU", "GU", "KI", "MH", "FM", "NR", "MP", "PW", "MH", "AS", "CK", "PF", "NU", "PN", "WS", "TK", "TO", "TV", "WF", "DZ", "MR", "MA", "DJ", "EG", "JO", "KW", "OM", "PS", "QA", "SA", "TR", "BZ", "CR", "SV", "GT", "HN", "MX", "PA", "AR", "BO", "BR", "CL", "CO", "EC", "PY", "PE", "SR", "UY", "DO", "IS", "BY", "MD", "RU", "UA", "LI", "CH", "AL", "ME", "MK", "RS", "VA", "NG", "GH", "CI", "NE", "BF", "SN", "GN", "BJ", "SL", "TG", "LR", "MR", "GM", "CV", "TZ", "KE", "UG", "MZ", "MG", "MW", "ZM", "RW", "MU", "DJ", "KM", "SC", "AO", "GA", "ST", "ZA", "NA", "BW", "LS", "SZ"];

export default {
    mounted() {
        // check prefLang cookie if non execute api call
        if (!this.getCookie('prefLang')) {
            this.getPrefLang()
        } else {
            if (supportedLocales.includes(this.$route.params.locale)) {
                this.$i18n.locale = this.$route.params.locale;
                if (this.getCookie('prefLang')) {
                    this.eraseCookie('prefLang')
                    this.setCookie("prefLang", this.$i18n.locale);
                    console.log(document.cookie)
                }
            } else {
                this.$i18n.locale = 'en'
                this.$router.push(`/${this.$i18n.locale}/404`);
            }
        }
    },
    created() {
    },
    methods: {
        async getPrefLang() {
            const { iso_country_code: countryCode } = await getLocation();

            console.log("flag 0", countryCode);

            if (countryCode) {
                this.setCookie('country', countryCode);
                if (partnerCountryCode.includes(countryCode)) this.setCookie('isSA', "true");
            }

            let found = false;
            for (let key in countryCodeLang) {
                if (key === countryCode) {
                    this.$i18n.locale = countryCodeLang[key]
                    this.setCookie("prefLang", this.$i18n.locale);
                    this.$router.push(`/${this.$i18n.locale}`);
                    found = true
                    break
                }
            }
            if (!found) {
                console.log("not found", found);
                this.$i18n.locale = 'en'
                console.log('no preffered language detected , defaulting to english')
                this.setCookie("prefLang", this.$i18n.locale);
            }
            window.location.reload()
        },
        setCookie(name, value) {
            document.cookie = name + "=" + (value || "")
        },
        getCookie(name) {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        },
        eraseCookie(name) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    },
    metaInfo() {
        return {
            title: i18n.t('common.titleSeo'),
            meta: [
                { name: 'description', content: i18n.t('common.descriptionSeo') },
                { name: 'keywords', content: i18n.t('common.keywordsSeo') }
            ]
        }
    },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto');

/* Font Family */
@font-face {
    font-family: "MicrosoftYaHei";
    src: url("@/assets/fonts/sc/Microsoft-YaHei-Light.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PingFangSCRegular";
    src: url("@/assets/fonts/sc/PingFang-SC-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "MicrosoftJhengHei";
    src: url("@/assets/fonts/tc/Microsoft-JhengHei.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "PingFangHKRegular";
    src: url("@/assets/fonts/tc/PingFangHK-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "segoeui";
    src: url("@/assets/fonts/en_kr_pt_es_th/segoeui.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "hiraginoKakuW3";
    src: url("@/assets/fonts/jp/Hiragino-Kaku-Gothic-Pro-W3.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "hiraginoKakuW6";
    src: url("@/assets/fonts/jp/Hiragino-Kaku-Gothic-ProN-W6.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "meiryo";
    src: url("@/assets/fonts/jp/MEIRYO.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "beVietnamProRegular";
    src: url("@/assets/fonts/vn/BeVietnamPro-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "notoNaskhArabic";
    src: url("@/assets/fonts/ar/NotoNaskhArabic-VariableFont_wght.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "roboto";
    src: url("@/assets/fonts/default/Roboto-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "msuighur";
    src: url("@/assets/fonts/msuighur.ttf");
    font-weight: normal;
    font-style: normal;
}

.sc,
.tc {
    font-family: "MicrosoftYaHei" !important;
}

.vn {
    font-family: "beVietnamProRegular" !important;
}

.jp {
    font-family: "hiraginoKakuW3", "hiraginoKakuW6", "meiryo" !important;
}

.en,
.th,
.kr,
.es,
.id,
.pt {
    font-family: "segoeui" !important;
}

.ar {
    font-family: "notoNaskhArabic" !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;



}


html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    overflow: hidden;
}

.container {
    max-width: 1632px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.container-1400 {
    max-width: 1440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

/* Buttons*/
.btn-primary {
    display: block;
    width: fit-content;
    font-size: 16px;
    text-decoration: none;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    text-align: center;
    color: #ffffff !important;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: #d91d22;
    cursor: pointer;

    &:hover {
        background-color: #ff4e53;
        color: #ffffff;
    }
}

.btn-secondary {
    display: block;
    width: fit-content;
    font-size: 16px;
    text-decoration: none;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 1.5px;
    text-align: center;
    color: #d91d22;
    padding: 12px 16px;
    border-radius: 4px;
    border: solid 1px #d91d22;
    background-color: #fff;
    cursor: pointer;

    &:hover {
        color: #ffffff;
        background-color: #ff4e53;
    }
}

a {
    text-decoration: none;
    color: #000018;

    &:hover {
        color: #ec3f33;
    }
}

video {
    width: 100%;
    height: fit-content;
}

img {
    width: 100%;
    height: auto;
}

/* Responsiveness */
.mobile-only {
    @include desktop-screen() {
        display: none !important;
    }
}

.desktop-only {
    @include mobile-screen() {
        display: none !important;
    }
}

.section-title {
    font-size: 48px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: center;
    color: #000018;

    @include mobile-screen() {
        font-size: 28px;
    }
}
</style>
