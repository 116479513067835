import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueFormulate from '@braid/vue-formulate'
import { zh } from '@braid/vue-formulate-i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies';

Vue.config.productionTip = false

Vue.use(VueFormulate, {
  plugins: [ zh ],
  locale: 'zh'
})
Vue.use(VueAxios, axios, VueCookies)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
