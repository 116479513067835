<template>
    <div id="page-home">
        <SectionBanner />
        <SectionBenefits />
        <SectionDataReporting />
        <!-- <SectionWhyUs /> -->
        <SectionTradingPlatform />    
        <SectionPaymentPlatform />
        <SectionContact />
    </div>
</template>

<script>
    import SectionBanner from './SectionBanner'
    import SectionBenefits from './SectionBenefits'
    import SectionDataReporting from './SectionDataReporting'
    // import SectionWhyUs from './SectionWhyUs'
    import SectionTradingPlatform from './SectionTradingPlatform'
    import SectionPaymentPlatform from './SectionPaymentPlatform'
    import SectionContact from './SectionContact'

    export default {
        name: "HomeView",
        components: {
            SectionBanner,
            SectionBenefits,
            SectionDataReporting,
            // SectionWhyUs,
            SectionTradingPlatform,
            SectionPaymentPlatform,
            SectionContact
        }
    }
</script>