<template>
    <main class="main">
      <template v-if="isMask === true">
        <MaskComponent />
      </template>
      <template v-else>
        <HeaderComponent />
        <router-view></router-view>
        <FooterComponent />
      </template>
    </main>
</template>

<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  import Mask from '@/components/Mask.vue'

  export default {
    name: "RouterRoot",
    components: {
      HeaderComponent: Header,
      FooterComponent: Footer,
      MaskComponent: Mask
    },
    methods: {
      checkQQBrowser() {
        if (/MicroMessenger|QQBrowser/.test(navigator.userAgent)) {
          this.isMask = true;
          document.title = "请使用浏览器打开";
        }
      }
    },
    mounted() {
      this.checkQQBrowser();
    },
    data () {
      return {
        isMask: false
      }
    }
  }
  </script>